import React from 'react';

import { Spin } from 'antd';

import { EmptyScreener } from './Screener';



export default function Loading() {
  return (
    <Spin size="large" tip="Loading...">
      <EmptyScreener />
    </Spin>     
  )
}