const _ = require('lodash')

const BASE_URL = 'https://api.nad.aleph.vc'

let _result = null

const getData = async sfConnector => {
  if (_result) {
    console.log(_result)
    return _result
  }
  const url = BASE_URL + '/?sf_access_token=' + sfConnector.getToken()
  const response = await fetch(url)
  const result = await response.json()
  _result = result
  return result
}

export const getCompanies = async sfConnector => {
  const result = await getData(sfConnector)
  return withSalesforceFields(sfConnector, result.companies)
} 

export const getTheses = async sfConnector => {
  const result = await getData(sfConnector)
  return result.theses
}

export const getReviewedCompanies = async (sfConnector) => {
  const res = await sfConnector.query(`
    SELECT Startup_Account__c,
      Startup_Account__r.Name,
      Startup_Account__r.Description,
      Usefulness__c,
      Feedback__c,
      Details__c,
      Signalling_Contact__r.Name,
      CreatedDate,
      Startup_Account__r.Linkedin_Internal_Company_Id__c,
      Startup_Account__r.NumberOfEmployees,
      Startup_Account__r.Employees__c,
      Startup_Account__r.Crunchbase__c,
      Startup_Account__r.Website,
      Startup_Account__r.Total_Invested__c
    FROM Signal__c
    WHERE Sub_Type__c = 'Thesis Review'
  `)
  return res.records
}

const mergeApiAndSalesforceResults = (apiResults, salesforceResults) => {
  const salesfroceResultMap = {}
  salesforceResults.forEach(r => {
    salesfroceResultMap[r.Id] = r
  })
  console.log(salesfroceResultMap)
  return apiResults.map(r => ({...r, sfRecord: salesfroceResultMap[r.sf_id] || {}}))
} 

const withSalesforceFields = async (sfConnector, apiResults) => {
  const fields = ['Id', 'Name']
  const apiCompanies = Object.values(apiResults)
  const allSfIds = [...new Set(apiCompanies.map(({ sf_id }) => sf_id))].filter(e => !!e)
  const sfResults =_.flatten((await Promise.all(_.chunk(allSfIds, 199).map(sfIds => sfConnector.query(`
    Select ${fields.join(', ')} from Account Where Id IN (${sfIds.map(id => `'${id}'`).join(', ')})
  `)))).map(result => result.records))
  return mergeApiAndSalesforceResults(apiCompanies, sfResults)
} 


export const submitReview = async (sfConnector, record, review) => {
  const contactId = await sfConnector.getContactId()
  const signal = {
    Startup_Account__c: record.sf_id,
    Signalling_Contact__c: contactId,
    Type__c: 'Opinion',
    Sub_Type__c: 'Thesis Review',
    Usefulness__c: review.rate,
    Feedback__c: review.review,
    Details__c: record.flagging_reasons.map(({ thesis_id, description }) => (
      'Reviewed during theses review: ' + description + ' (https://app.redash.io/alephvc/queries/'+thesis_id+')'
    )).join('\n\n')
  }
  await sfConnector.connection.sobject('Signal__c').insert(signal)
  return ({
    ...signal,
    Startup_Account__r: {
      Name: record.name,
      Description: record.description
    }
  })
} 


export const passOnCompany = (sfConnector, linkedin_id) => {
  return fetch(BASE_URL + '/pass?linkedin_id=' + linkedin_id+ '&sf_access_token=' + sfConnector.getToken(), {
    method: 'POST',
  })
}
