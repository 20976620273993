import React from 'react';

import { Layout, PageHeader } from 'antd';

const { Content } = Layout;

export default function Container({ subTitle = "NAD", children }) {
  return (
    <div>
      <Layout>
        <PageHeader
          avatar={{ src: './logo192.png' }}
          title="Aleph"
          subTitle={subTitle}
        />
        <Content>
            {children}
        </Content>
      </Layout>
    </div>
  )
}