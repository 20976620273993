import React from 'react';
import { Modal, Button, Icon} from 'antd';


import { EmptyScreener } from './Screener'

export default function Login({onClick}) {
  return (
    <div>
      <EmptyScreener/>
      <Modal
        closable={false}
        visible={true}
        footer={[
          <Button key="submit" type="primary" loading={false} onClick={onClick}>
            <Icon type="ant-cloud" /> Login With Salesforce
          </Button>,
        ]}
      >
        <p>Please login to continue</p>
      </Modal>
    </div>
  );
}

