import _ from 'lodash'
import { getSfConfig } from './config'
import jsforce from 'jsforce';


export class SalesforceConnector {
  
  connection = null
  
  _isReady = null
  
  _user_contact_map = {}
  
  constructor(getConfig = getSfConfig) {
    jsforce.browser.init(getConfig())
    
    this._isReady = new Promise((resolve) => {
      this._setIsReady = resolve
    })
    
    jsforce.browser.on('connect', _.once(this._setConnection))
    
    this.onceReady = _.once(async handler => {
      await this._isReady
      handler()
    })
  }
  
  getToken = () => {
    return this.connection.accessToken
  }
  
  _setConnection = (conn) => {
    console.log('setting connection!', conn, this)
    this.connection = conn;
    this._setIsReady()
  }
  
  login() {
    jsforce.browser.login()
  }
  
  isLoggedIn = () => jsforce.browser.isLoggedIn()
  
  query = async (...args) => {
    await this._isReady

    const records = [];
    return new Promise((resolve, reject) => {
      const query = this.connection.query(...args)
      .on("record", function(record) {
        records.push(record);
      })
      .on("end", function() {
        console.log("total in database : " + query.totalSize);
        console.log("total fetched : " + query.totalFetched);
        resolve({ records })
      })
      .on("error", function(err) {
        reject(err);
        console.error(err);
      })
      .run({ autoFetch : true, maxFetch : 4000 }); // synonym of Query#execute();
    })


    return this.connection.query( ...args).run({ autoFetch : true, maxFetch : 4000 })
  }
  
  getUser = async () => {
    const res = await this.query(`Select Id, Contact_Id__c, Name From User`)
    console.log(res)
    return res.records[0]
  }

  getContactId = async () => {
    const userId = this.connection.userInfo.id
    if (this._user_contact_map[userId]) {
      return this._user_contact_map[userId]
    }
    const res = await this.query(`Select Id, Contact_Id__c From User`)
    res.records.forEach(({ Id, Contact_Id__c }) => {
      this._user_contact_map[Id] = Contact_Id__c
    })
    return this._user_contact_map[userId]
  }
  
}