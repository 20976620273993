import React, { useState } from 'react';
import { Table, Tag, Button, Icon, Input } from 'antd';

import _ from 'lodash'

import ReviewForm from './ReviewForm'

const { Search } = Input;


const ButtonGroup = Button.Group;

let lastFakeIdx = 0;

const getFakeId = () => lastFakeIdx++;

const fakeChar = '━'

const fakeWord = () => fakeChar.repeat(_.random(5, 15))

const fakeName = () => _.range(1, _.random(2, 3)).map(fakeWord).join(' ')

const fakeThesis = () => _.range(5, 10).map(fakeWord).join(' ')

const fakeDescription = () => _.range(10, 60).map(fakeWord).join(' ')

const fakeAccount = () => ({
  name: fakeName(),
  flagging_reasons: [{
    thesis_id: 0,
    description: fakeThesis()
  }],
  description: fakeDescription(),
  linkedin_id: getFakeId(),
  sf_id: 1,
})


export function EmptyScreener() {
  
  const companies = Array(500).fill(null).map(n => fakeAccount())
  companies.displayLength = fakeChar.repeat(2)
  return <AccountScreener companies={companies}></AccountScreener>

}

const withFormId = companies => companies.map(c => ({ ...c, form_id: c.sf_id }))

export default function AccountScreener({ companies, theses = [], onAccountReviewed = _.noop, onAccountPassed = _.noop }) {
  
  const [searchTerm, setSearchTerm] = useState('')
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <p className="name">{text}</p>
    },
    {
      title: 'Flagging Reasons',
      dataIndex: 'flagging_reasons',
      key: 'flagging_reasons',
      filters: theses.map(({ id, name }) => ({
        text: name,
        value: id
      })),
      onFilter: (value, record) => record.flagging_reasons.filter(({ thesis_id }) => thesis_id == value).length !== 0,
      render: reasons => <p className="flaggingReasons">{
        reasons.map(({ thesis_id, description }, idx) => (
          <div>
            <a key={description+thesis_id} target="_blank" href={"https://app.redash.io/alephvc/queries/"+thesis_id}>{description}</a>{ (reasons.length > 0 && idx !== reasons.length - 1) ? <hr/> : null}
          </div>
        ))
      }</p>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: text => <p className="description">{text}</p>
    },
    {
      title: 'Linkedin',
      dataIndex: 'linkedin_id',
      key: 'linkedin_id',
      render: linkedin_id => <a target="_blank" href={"http://www.linkedin.com/companies/"+linkedin_id}>Linkedin</a>,
    },
    {
      title: 'Salesforce',
      dataIndex: 'sf_id',
      key: 'sf_id',
      render: sf_id => sf_id ? (<a target="_blank" href={"https://aleph.lightning.force.com/lightning/r/Account/"+sf_id+"/view"}>Salesforce</a>) : null,
    },
    {
      title: 'Feedback',
      dataIndex: 'form_id',
      key: 'form_id',
      render: (sf_id, record) => sf_id ? (
        <ReviewForm form_id={sf_id} onSubmit={_.partial(onAccountReviewed, record)}/>
      ) : (
        <div>
          <Tag>Oops! Account not in SalesForce yet. <br/> Please add it manually and refresh the page.</Tag><br/>
          <Button onClick={_.partial(onAccountPassed, record)}>Pass</Button>
        </div>
      )
    }
  ]

  const companiesToDisplay = companies.filter(({ name, description }) => (name + description).toLowerCase().indexOf(searchTerm.toLowerCase()) != -1)

  return (
    <div>
      <div style={{ padding: 15 }}>
        <Icon type="bell" /> <b>Total pending review:</b> <Tag>{companies.displayLength || companies.length}</Tag> |&nbsp;  
        <Search
          placeholder="input search text"
          onSearch={setSearchTerm}
          style={{ width: 200 }}
        />
      </div>
      <Table rowKey='linkedin_id' className="screener" columns={columns} dataSource={withFormId(companiesToDisplay)} />
    </div>
  )
}

