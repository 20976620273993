import React, { useState } from 'react';
import "antd/dist/antd.css";
import './App.css';

import { Menu, Icon } from 'antd';

import {
  SalesforceConnector
} from '@Common/sf-connector'

import { getCompanies, getTheses, getReviewedCompanies, submitReview, passOnCompany } from './API'

import Container from '@Common/Components/Container'

import AccountScreener from './Components/Screener'
import History from './Components/History'
import ThesesOverview from './Components/ThesesOverview'
import Loading from './Components/Loading'
import Login from './Components/Login'

import _ from 'lodash'

const SCREENER_VIEW = 'SCREENER_VIEW'
const HISTORY_VIEW = 'HISTORY_VIEW'
const THESES_OVERVIEW = 'THESES_OVERVIEW'

const salesforceConnector = new SalesforceConnector()



function App({ currentView }) {

  const [isLoggedIn, setIsLoggedIn] = useState(salesforceConnector.isLoggedIn())
  const [isLoading, setIsLoading] = useState(true)
  const [theses, setTheses] = useState([])
  const [companies, setCompanies] = useState([])
  const [reviewedCompanies, setReviewedCompanies] = useState([])
  const [passedCompanies, setPassedCompanies] = useState([])
  
  const initApp = async () => {
    setIsLoggedIn(true)
    setIsLoading(true)
    const [allCompanies, sfRevieweCompanies] = await Promise.all([getCompanies(salesforceConnector), getReviewedCompanies(salesforceConnector)])
    setTheses(await getTheses(salesforceConnector))
    setReviewedCompanies(sfRevieweCompanies)
    setCompanies(allCompanies)
    setIsLoading(false)
  };

  function login(){
    setIsLoading(true);
    salesforceConnector.login();
  }

  async function reviewCompany(record, review) {
    const res = await submitReview(salesforceConnector, record, review)
    setReviewedCompanies([...reviewedCompanies, res])
  }

  function passCompany(record) {
    setPassedCompanies([...passedCompanies, record.linkedin_id])
    return passOnCompany(salesforceConnector, record.linkedin_id)
  }

  function getFilterdCompanies() {
    return companies.filter(c => {
        return !_.includes(_.map(reviewedCompanies, 'Startup_Account__c'), c.sf_id) && !_.includes(passedCompanies, c.linkedin_id)
    })
  }

  salesforceConnector.onceReady(initApp)
  
  if (!isLoggedIn) {
    return <Login onClick={login}/>
  }
  
  if (isLoading) {
    return <Loading/>
  }

  if (currentView === SCREENER_VIEW) {
    return (
      <AccountScreener
          theses={theses}
          companies={getFilterdCompanies()}
          onAccountReviewed={reviewCompany}
          onAccountPassed={passCompany}
      />
    )
  }

  if (currentView === HISTORY_VIEW) {
    return <History reviews={reviewedCompanies} />
  }

  if (currentView === THESES_OVERVIEW) {
    return <ThesesOverview theses={theses} reviews={reviewedCompanies} />
  }

  return null
}


export default function() {
  const [currentView, setCurrentView] = useState(SCREENER_VIEW)
  const menuClickHandler = (e) => setCurrentView(e.key)
  return (
    <Container>
      <Menu onClick={menuClickHandler} selectedKeys={currentView} mode="horizontal" theme="dark">
        <Menu.Item key={SCREENER_VIEW}>
          <Icon type="unordered-list" />
          Screener
        </Menu.Item>
        <Menu.Item key={HISTORY_VIEW}>
          <Icon type="history" />
          History
        </Menu.Item>
        <Menu.Item key={THESES_OVERVIEW}>
          <Icon type="bar-chart" />
          Theses
        </Menu.Item>
      </Menu>
      <App currentView={currentView}/>
    </Container>
  )
};