import React from 'react';
import {
  Form,
  Button,
  Rate,
  Input,
} from 'antd';

class Demo extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values)
      }
    });
  };



  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit} key={this.props.form_id}>
        <Form.Item key={this.props.form_id}>
          {getFieldDecorator('review', {})(
            <Input.TextArea
              key={this.props.form_id}
              id={this.props.form_id}
              autoSize={{ minRows: 1, maxRows: 5 }}
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('rate', {
            initialValue: 1,
            count:3,
          })(<Rate />)}
        </Form.Item>


        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'validate_other' })(Demo);
  
